import PropTypes from 'prop-types';
// import { BtnSchedule } from '../../components';
import { Nav, NavContainer, NavMenu, NavItem, NavLink } from './SidebarStyles';

const Sidebar = ({ isOpen, toggle }) => {
	return (
		<Nav isOpen={isOpen} onClick={toggle}>
			<NavContainer>
				<NavMenu>
					<NavItem>
						<NavLink
							to="home"
							onClick={toggle}
							smooth
							duration={500}
							spy
							exact="true"
							offset={-72}
						>
							Startseite
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							to="therapeut"
							onClick={toggle}
							smooth
							duration={500}
							spy
							exact="true"
							offset={-72}
						>
							Therapeut
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							to="praxis"
							onClick={toggle}
							smooth
							duration={500}
							spy
							exact="true"
							offset={-72}
						>
							Praxis
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							to="leistungen"
							onClick={toggle}
							smooth
							duration={500}
							spy
							exact="true"
							offset={-72}
						>
							Leistungen
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							to="kontakt"
							onClick={toggle}
							smooth
							duration={500}
							spy
							exact="true"
							offset={-48}
						>
							Kontakt
						</NavLink>
					</NavItem>
					{/* <BtnSchedule icon size="20px" color="#ffffff" /> */}
				</NavMenu>
			</NavContainer>
		</Nav>
	);
};

export default Sidebar;

// Declaring PropTypes (eslint)
Sidebar.propTypes = {
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
};
