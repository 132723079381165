import PropTypes from 'prop-types';
import {
	BrandAnimate,
	BrandContainer,
	BrandLogo,
	BrandNames,
	BrandTopline,
	BrandHeadline,
} from './BrandStyles';
import logoImg from '../../assets/img/brand/logo1.svg';

const Brand = props => (
	<BrandAnimate
		variants={aBrand}
		initial="hidden"
		animate="show"
		whileHover="hover"
	>
		<BrandContainer
			to="home"
			smooth
			duration={500}
			spy
			exact="true"
			offset={-144}
		>
			<BrandLogo src={logoImg} alt="logo" />
			<BrandNames>
				<BrandTopline scroll={props.scroll}>
					praxis&nbsp;f&uuml;r&nbsp;osteopathie
				</BrandTopline>
				<BrandHeadline scroll={props.scroll}>Torben&nbsp;Hansen</BrandHeadline>
			</BrandNames>
		</BrandContainer>
	</BrandAnimate>
);

export default Brand;

// Declaring PropTypes (eslint)
Brand.propTypes = {
	scroll: PropTypes.bool,
};

// Animations
const aBrand = {
	hidden: {
		opacity: 0,
		y: -100,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			// delay: 2,
			delay: 1.5,
			type: 'spring',
			stiffness: 140,
			mass: 1.2,
		},
	},
	hover: {
		scale: 1.05,
		transition: { duration: 0.3 },
	},
};
