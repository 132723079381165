import styled from 'styled-components/macro';
import { Link as LinkS } from 'react-scroll';
import { motion } from 'framer-motion';

export const Nav = styled.nav`
	width: 100%;
	height: 96px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: sticky;
	top: 0;
	z-index: 10;
	background: transparent;
	/* overflow: hidden; */

	@media screen and (max-width: 960px) {
		transition: 0.8s all ease;
	}
`;

export const NavBG = styled.div`
	width: 100%;
	height: 96px;
	position: fixed;
	z-index: 10;
	top: 0;
	left: 0;
	background: ${({ scroll }) => (scroll ? '#fff' : 'transparent')};
	transition: all 0.3s ease;
`;

export const NavAnimate = styled(motion.div)`
	@media screen and (max-width: 1024px) {
		display: none;
	}
`;

export const NavbarContainer = styled.div`
	width: 90%;
	height: 96px;
	display: flex;
	justify-content: space-between;
	z-index: 1;

	@media screen and (min-width: 1025px) {
		width: 80%;
	}
`;

export const MobileIcon = styled(motion.div)`
	display: flex;
	width: 3rem;
	height: 4.8rem;
	z-index: 50;
	flex-flow: column nowrap;
	align-self: center;
	justify-content: space-around;
	padding: 1rem 0;
	box-sizing: border-box;
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	div {
		width: 100%;
		height: 0.2rem;
		background-color: ${({ scroll }) =>
			scroll ? `var(--clr-middle)` : `var(--clr-white)`};
		border-radius: 1rem;
		transform-origin: 1px;
		transition: all 0.25s ease;

		&:nth-child(1) {
			transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
			background-color: ${({ isOpen }) => (isOpen ? `var(--clr-white)` : '')};
		}
		&:nth-child(2) {
			display: ${({ isOpen }) =>
				isOpen ? 'translateX(100%)' : 'translateX(0)'};
			opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
		}
		&:nth-child(3) {
			transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
			background-color: ${({ isOpen }) => (isOpen ? `var(--clr-white)` : '')};
		}
	}

	@media screen and (min-width: 1025px) {
		display: none;
	}
`;

export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	list-style: none;
	text-align: center;
	color: ${({ scroll }) => (scroll ? '#666' : '#fff')};
	transition: all 0.4s ease;

	@media screen and (max-width: 1024px) {
		display: none;
	}
`;

export const NavItem = styled.li`
	height: 96px;
	display: flex;
	align-items: center;
`;

export const NavLink = styled(LinkS)`
	height: 40%;
	padding: 0 1.6rem;
	position: relative;
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 1.6rem;
	font-weight: 100;
	letter-spacing: 2.5px;
	color: inherit;
	cursor: pointer;

	&:hover,
	&:focus {
		color: var(--clr-primary);
	}

	&.active {
		color: var(--clr-primary-dark);
		cursor: unset;

		&::before {
			margin-top: 1px;
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			border-bottom: 1px solid var(--clr-primary-dark);
		}
	}
`;
