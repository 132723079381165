import {
	FooterSection,
	Container,
	Content,
	Items,
	Link,
	Rights,
} from './FooterStyles';

const Footer = () => {
	return (
		<FooterSection id="footer">
			<Container>
				<Content>
					<Items>
						<h3>Ich arbeite zusammen mit</h3>
						<a
							href="https://www.osteokompass.de/patienteninfo-krankenkassen"
							rel="noopener noreferrer"
						>
							Osteokompass Patienteninfo Krankenkassen
						</a>
						<a
							href="https://www.die-hebammerei-appen.de/default.html"
							rel="noopener noreferrer"
						>
							&quot;Die Hebammerei&quot; in Appen
						</a>
						<a href="https://www.sehtuechtig.com/" rel="noopener noreferrer">
							Sehschule &quot;Seht&uuml;chtig&quot; in Appen
						</a>
					</Items>
					<hr />
					<Items>
						<h3>Rechtliches</h3>
						<Link to="impressum">Impressum</Link>
						<Link to="datenschutz">Datenschutzerkl&auml;rung</Link>
					</Items>
					<hr />
					{/* <Items>
						<h3>Ihre Cookie Einstellungen</h3>
						<Link to="cookie">Cookie Einstellungen &auml;ndern</Link>
					</Items> */}
				</Content>
			</Container>
			<Rights>
				<p>&#169; Hansen Osteopathie - {new Date().getFullYear()}</p>
			</Rights>
		</FooterSection>
	);
};

export default Footer;
