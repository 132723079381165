import styled from 'styled-components/macro';
import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
	width: 100%;
	min-height: 100vh;
	position: fixed;
	background: #579490;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	right: 0;
	transform: ${({ isOpen }) =>
		isOpen ? 'translateY(0)' : 'translateY(-100%)'};
	z-index: 20;

	@media screen and (max-width: 1024px) {
		transition: 0.4s all ease;
	} ;
`;

export const NavContainer = styled.div`
	width: 100%;
	height: 60vh;
	margin-top: 24px;
	display: flex;
	justify-content: center;
`;

export const NavMenu = styled.ul`
	width: 100%;
	height: 100%;
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	align-items: center;
	list-style: none;
	text-align: center;
	z-index: 30;

	@media screen and (min-width: 1025px) {
		display: none;
	}
`;

export const NavItem = styled.li`
	width: 100%;
	display: flex;
	justify-content: center;
	position: relative;
`;

export const NavLink = styled(LinkS)`
	height: 100%;
	position: relative;
	display: flex;
	align-items: center;
	text-decoration: none;
	text-transform: uppercase;
	font-size: 2rem;
	font-weight: 100;
	letter-spacing: 1.3px;
	color: var(--clr-white);
	cursor: pointer;

	&.active {
		color: var(--clr-sidebar-links);
		cursor: unset;
	}

	&:not(.active):hover,
	&:not(.active):focus {
		color: var(--clr-sidebar-links);
	}

	&:not(.active):after {
		content: '';
		position: absolute;
		bottom: -8px;
		left: 0;
		width: 100%;
		transform: scaleX(0);
		border-bottom: 1px solid var(--clr-sidebar-links);
		transition: 0.4s all ease-in-out;
	}

	&:not(.active):hover {
		&:not(.active):after {
			width: 100%;
			transform: scaleX(1);
			transform-origin: center;
		}
	}

	@media screen and (min-width: 768px) {
		font-size: 2.4rem;
	}
`;
