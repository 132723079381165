import { Suspense, useState } from 'react';
import {
	Toolbar,
	Hero,
	Therapeut,
	Praxis,
	Leistungen,
	Kontakt,
} from '../components';

const Home = () => {
	// Nav State
	const [isOpen, setIsOpen] = useState(false);

	const toggle = () => {
		setIsOpen(!isOpen);
	};

	// Accordion State
	const [clicked, setClicked] = useState(null);

	const toggleAccordion = id => {
		if (clicked === id) {
			return setClicked(null);
		}

		setClicked(id);
	};

	return (
		<>
			<Toolbar isOpen={isOpen} toggle={toggle} />
			<main>
				<Hero />
				<Suspense>
					<Therapeut />
					<Praxis />
					<Leistungen clicked={clicked} toggleAccordion={toggleAccordion} />
					<Kontakt />
				</Suspense>
			</main>
		</>
	);
};

export default Home;
