import PropTypes from 'prop-types';
import { Data } from './Data';
import { DetailItem, Detail } from './DetailsStyles';

const AccordionDetail = ({ clicked, toggleAccordion }) => {
	return (
		<>
			{Data.map((item, id) => {
				return (
					<DetailItem clicked={clicked} key={item.id}>
						{clicked === id ? (
							<Detail
								key={item.id}
								clicked={clicked}
								onClick={() => toggleAccordion(id)}
							>
								{item.detail}
							</Detail>
						) : null}
					</DetailItem>
				);
			})}
		</>
	);
};

export default AccordionDetail;

// Declaring PropTypes (eslint)
AccordionDetail.propTypes = {
	clicked: PropTypes.number,
	toggleAccordion: PropTypes.func,
};
