import styled from 'styled-components';
import PropTypes from 'prop-types';

const LayoutContainer = styled.div`
	width: 100%;
	height: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

const Layout = ({ children }) => {
	return <LayoutContainer>{children}</LayoutContainer>;
};

export default Layout;

// Declaring PropTypes (eslint)
Layout.propTypes = {
	children: PropTypes.any,
};
