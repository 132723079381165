export const Data = [
	{
		id: 1,
		leistung: 'Osteo für Erwachsene',
		detail: (
			<>
				<p>
					Nach einem ausführlichen Anamnesegespräch werden Sie osteopathisch/aus
					heilpraktischer Sicht untersucht und behandelt.
				</p>
				<p>
					Preis und Behandlungszeit richten sich nach der GeBüH und können
					variieren.
				</p>
			</>
		),
	},
	{
		id: 2,
		leistung: 'Kinderosteopathie',
		detail: (
			<div>
				<p>
					Meine Behandlung eignet sich für Säuglinge, Kleinkinder, Jugendliche,
					Schwangere und Mütter.
				</p>
			</div>
		),
	},
	{
		id: 3,
		leistung: 'Osteo für Sportler',
		detail: (
			<div>
				<p>
					Bereits seit vielen Jahren betreue ich Sportler im Bereich Basketball,
					Fußball, Hockey, Tennis, Handball und Golf
				</p>
			</div>
		),
	},
	{
		id: 4,
		leistung: 'Kosten',
		detail: (
			<div>
				<p>
					Für Privat- und Zusatzversicherte rechne ich auf der Grundlage der
					Gebührenverordnung für Heilpraktiker ab.
				</p>
				<p>
					Osteopathische Behandlungen werden von den privaten Krankenkassen je
					nach Konditionen übernommenn
				</p>
				<p>
					Seit 01.02.2020 übernimmt die Hamburger Beihilfe keine Heilpraktiker
					Behandlungen mehr.
				</p>
				<p>
					Die Kosten der gesetzlich Versicherten kann je nach Kondition der
					jeweiligen Kasse bezuschusst werden. Bitte informieren Sie sich bei
					Ihrer Versicherung.
				</p>
				<p>
					Weitere Informationen unter
					<br />
					<a
						href="https://www.osteokompass.de/patienteninfo-krankenkassen"
						target="_blank"
						rel="noopener noreferrer"
					>
						https://www.osteokompass.de
					</a>
				</p>
			</div>
		),
	},
];
