import styled from 'styled-components/macro';
import img_bg from '../../assets/img/therapeut/torben.png';
import { motion } from 'framer-motion';

export const TherapeutSection = styled.section`
	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: #f3f3f3;

	@media screen and (min-width: 1350px) {
		background: none;
		min-height: calc(100vh - 96px);
		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			bottom: 0;
			right: 0;
			z-index: -1;
			background: url(${img_bg}) #f3f3f3;
			background-size: contain;
			background-repeat: no-repeat;
			background-position: right 50px;
		}
	}
`;

export const TherapeutContainer = styled.div`
	width: 90%;
	margin: var(--margin-mobile-auto);
	margin-bottom: 48px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	overflow: hidden;

	@media screen and (min-width: 768px) {
		margin: var(--margin-tablet-auto);
	}

	@media screen and (min-width: 1200px) {
		width: 80%;
		margin: var(--margin-desktop-auto);
		align-items: left;
		&::before {
			content: '';
			position: absolute;
			min-height: calc(100vh - 300px);
			width: 90%;
			top: -32px;
			z-index: -1;
			/* background-image: url(${img_bg}); */
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center right;
			border-radius: 12px;
		}
	}
`;

export const Top = styled(motion.div)`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 1025px) {
		flex-flow: row-reverse;
		justify-content: left;
	}
`;

export const SmallImg = styled.picture`
	width: 100%;
	height: auto;
	max-width: 100%;
	margin: 0 0 32px 0;
	border-radius: 4px;

	img {
		width: 100%;
		height: auto;
		max-width: 100%;
		margin: 0 0 32px 0;
		border-radius: 4px;
	}

	@media screen and (min-width: 768px) {
		width: 60%;
		height: auto;
		max-width: 100%;
		margin: 0 0 48px 0;
		border-radius: 8px;
	}

	@media screen and (min-width: 1350px) {
		display: none;
	}
`;

export const Headlines = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	h2 {
		margin: 0;
	}

	@media screen and (min-width: 1025px) {
		align-items: flex-start;
	}
`;

export const Content = styled(motion.div)`
	width: 100%;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 768px) {
		P {
			align-self: flex-start;
			text-align: left;
		}
	}

	@media screen and (min-width: 1200px) {
		width: 60%;
		align-self: flex-start;
		font-size: 2rem;

		p {
			align-self: flex-start;
			text-align: left;
		}
	}
`;

export const SliderAnimate = styled(motion.div)`
	width: 100%;
	display: flex;
	flex-direction: column;

	@media screen and (min-width: 1200px) {
		width: 60%;
		align-self: flex-start;
		font-size: 2rem;
	}

	@media screen and (min-width: 1500px) {
		h3 {
			margin-top: 48px;
		}
	}
`;

export const SliderContainer = styled(motion.div)`
	width: 100%;
	display: flex;
	align-items: center;
	overflow: hidden;

	@media screen and (min-width: 1025px) {
		width: 95%;
	}
`;
