import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const BtnScheduleContainer = styled(motion.div)`
	position: ${props => (props.bottom ? 'absolute' : 'relative')};
	bottom: ${props => (props.bottom ? '16px' : null)};
	right: ${props => (props.bottom ? '16px' : null)};
	margin: ${props => props.margin};
	padding: 12px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	width: fit-content;
	border: none;
	border-radius: 50%;
	background: var(--grad-btn-primary);
	text-shadow: ${props => (props.icon ? 'none' : null)};
	box-shadow: var(--bsd-btn-schedule);
	cursor: pointer;

	@media screen and (min-width: 768px) {
		padding: 16px;
		bottom: ${props => (props.bottom ? props.position : null)};
		right: ${props => (props.bottom ? props.position : null)};
	}

	@media screen and (min-width: 1025px) {
		display: ${props => (props.bottom ? 'none' : 'inline-flex')};
		padding: 12px;
	}
`;
