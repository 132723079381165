import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const KontaktSection = styled.section`
	width: 100%;
	min-height: calc(100vh - 96px);
	display: flex;
	position: relative;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	z-index: 0;
	/* background: var(--grad-primary-tb-light); */
	overflow: hidden;

	@media screen and (min-width: 768px) {
		padding: 0;
	}
`;

export const KontaktContainer = styled.div`
	width: 100%;
	margin: var(--margin-mobile-auto);
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	hr {
		margin: 0;
	}

	@media screen and (min-width: 768px) {
		margin: var(--margin-tablet-auto);

		hr {
			margin: 32px 0;
		}
	}

	@media screen and (min-width: 1350px) {
		width: 80%;
		flex-direction: row;
		align-items: flex-start;

		hr {
			display: none;
		}
	}
`;

export const Left = styled(motion.div)`
	width: 100%;
	margin-bottom: 48px;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	.spacer {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (min-width: 1025px) {
			width: 600px;
		}
	}

	p {
		margin: 0;
	}

	.route {
		margin: 24px 24px 0;

		p {
			color: var(--clr-primary);

			&:hover {
				color: var(--clr-secondary-dark);
			}
		}
	}

	img {
		margin: 8px 0;
	}

	@media screen and (min-width: 768px) {
		margin-bottom: 24px;
	}

	@media screen and (min-width: 1350px) {
		width: 50%;
		margin: 0;
		align-items: flex-start;
		border-radius: 15px;
		font-size: 2rem;

		.route {
			align-self: center;
		}
	}
`;

export const Right = styled(motion.div)`
	padding: 16px 24px 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;

	h2 {
		margin: 24px 0 16px;
	}

	.form {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	form {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;

		@media screen and (min-width: 768px) {
			width: 450px;
		}

		/* @media screen and (min-width: 1281px) {
			margin-top: 56px;
		} */

		div {
			width: 100%;
			margin: 0 0 24px 0;

			&:first-child {
				margin-top: 16px;
			}

			// Submit Button Container
			&:last-child {
				width: fit-content;
				margin: 0;
				padding: 6px 22px 8px 16px;
				border-radius: 50px;
				background: var(--grad-btn-primary);
				box-shadow: var(--bsd-btn);
				transition: all 0.3s ease-out;
			}

			input {
				padding: 8px 0 4px 8px;
				width: 100%;
				border: none;
				border-bottom: 1px solid var(--clr-middle-grey);
				color: var(--clr-content);
				background: transparent;

				&:focus {
					outline: none;
					border-bottom: 1px solid var(--clr-primary);
				}
			}

			textarea {
				margin: 16px 0 8px;
				padding: 8px;
				width: 100%;
				border: 1px solid var(--clr-middle-grey);
				border-radius: 5px;
				resize: vertical;
				background: transparent;

				&:focus {
					outline: none;
					border: 1px solid var(--clr-primary);
					background: var(--clr-primary-light);
				}
			}

			input[type='submit'] {
				border: none;
				border-radius: 50px;
				color: var(--clr-white);
				text-shadow: var(--tsd-primary);
				background: transparent;
				text-transform: uppercase;
				cursor: pointer;

				@media screen and (max-width: 340px) {
					font-size: 1.4rem;
				}
			}

			input,
			textarea {
				font-size: 1.6rem;

				&::-webkit-input-placeholder {
					/* Chrome/Opera/Safari */
					font-size: 1.3rem;
					color: #666;
				}
				&::-moz-placeholder {
					/* Firefox 19+ */
					font-size: 1.3rem;
					color: #666;
				}
				&:-ms-input-placeholder {
					/* IE 10+ */
					font-size: 1.3rem;
					color: #666;
				}
				&:-moz-placeholder {
					/* Firefox 18- */
					font-size: 1.3rem;
					color: #666;
				}
			}

			@media screen and (max-width: 340px) {
				input,
				textarea {
					font-size: 1.4rem;
				}
			}

			@media screen and (min-width: 768px) {
				input,
				textarea {
					font-size: 1.8rem;
				}
			}
		}
	}

	@media screen and (min-width: 768px) {
		padding: 0;

		h2 {
			margin: 24px 0 16px;
		}
	}

	@media screen and (min-width: 1025px) {
		width: 50%;
		margin: 24px 0 0;
		background: none;
	}
`;

export const Card = styled.div`
	width: 90%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	border-radius: 4px;
	color: var(--clr-content);

	&::before,
	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 4px;
	}

	&::before {
		opacity: 0.5;
		background: var(--grad-primary-reverse);
		box-shadow: var(--bsd-middle);
	}

	&::after {
		opacity: 0.1;
		border: 1px solid var(--clr-middle-grey);
	}

	h1 {
		margin: 0 0 24px 0;
		font-size: 2.4rem;
		letter-spacing: 1.3px;
		font-weight: 700;
		line-height: 1;
	}

	h2 {
		margin: 24px 0 0 0;
		letter-spacing: 1.7px;
		line-height: 0.8;
	}

	@media screen and (max-width: 340px) {
		h1 {
			font-size: 1.8rem;
			letter-spacing: 2px;
			line-height: 0.8;
		}
	}

	@media screen and (min-width: 768px) {
		padding: 48px 0 0;
		width: 450px;

		h1 {
			font-size: 3.6rem;
			letter-spacing: 1.8px;
		}
	}
`;

export const CardTop = styled.div`
	padding: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const CardPhone = styled.div`
	margin: 24px 0 8px;
	font-size: 1.6rem;
	text-align: left;

	a {
		font-weight: 100;
	}
`;

export const CardBottom = styled.div`
	width: 100%;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
	background: var(--card-bottom);

	@media screen and (min-width: 1025px) {
		padding: 0 0 32px;
	}
`;

export const CardTimes = styled.div`
	padding: 24px 0 32px 0;

	div {
		margin-top: 16px;
	}

	.small {
		padding: 16px 0 8px 0;
		font-size: 1.2rem;
	}
`;

export const CardTime = styled.div`
	margin-top: 16px;
	display: flex;
	justify-content: space-around;
`;

export const RouteImg = styled.picture``;
