import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import img1Jpg from '../../assets/img/praxis/praxis1.jpg';
import img3Jpg from '../../assets/img/praxis/praxis3.jpg';
import img2Jpg from '../../assets/img/praxis/praxis2.jpg';
import img1Webp from '../../assets/img/praxis/praxis1.webp';
import img2Webp from '../../assets/img/praxis/praxis2.webp';
import img3Webp from '../../assets/img/praxis/praxis3.webp';
import {
	PraxisSection,
	PraxisContainer,
	ImgContainer,
	ImgItem,
} from './PraxisStyles';

const Praxis = () => {
	const { ref, inView } = useInView({ threshold: 0.2 });
	const animationTitle = useAnimation();
	const animationImg1 = useAnimation();
	const animationImg2 = useAnimation();
	const animationImg3 = useAnimation();

	useEffect(() => {
		if (inView) {
			animationTitle.start({
				opacity: 1,
				y: 0,
				transition: {
					type: 'spring',
					duration: 2,
				},
			});
			animationImg1.start({
				opacity: 1,
				x: 0,
				transition: {
					type: 'tween',
					duration: 0.8,
					ease: 'easeInOut',
				},
			});
			animationImg2.start({
				opacity: 1,
				x: 0,
				transition: {
					type: 'tween',
					// delay: 0.1,
					duration: 1,
					ease: 'easeInOut',
				},
			});
			animationImg3.start({
				opacity: 1,
				x: 0,
				transition: {
					type: 'tween',
					// delay: 0.1,
					duration: 1,
					ease: 'easeInOut',
				},
			});
		}
		if (!inView) {
			animationTitle.start({
				opacity: 0,
				y: 30,
			});
			animationImg1.start({
				opacity: 0,
				x: -30,
			});
			animationImg2.start({
				opacity: 0,
				x: 30,
			});
			animationImg3.start({
				opacity: 0,
				x: 30,
			});
		}
	});

	return (
		<>
			<PraxisSection id="praxis">
				<PraxisContainer ref={ref}>
					<ImgContainer>
						<ImgItem animate={animationImg1}>
							<picture>
								<source
									srcSet={img1Webp}
									type="image/webp"
									alt="Praxis Hauptraum"
								/>
								<img src={img1Jpg} loading="lazy" alt="Praxis Hauptraum" />
							</picture>
						</ImgItem>
						<ImgItem animate={animationImg2}>
							<picture>
								<source
									srcSet={img2Webp}
									type="image/webp"
									alt="Praxis Nebenraum"
								/>
								<img src={img2Jpg} loading="lazy" alt="Praxis Nebenraum" />
							</picture>
						</ImgItem>
						<ImgItem animate={animationImg3}>
							<picture>
								<source
									srcSet={img3Webp}
									type="image/webp"
									alt="Praxis Wartezimmer"
								/>
								<img src={img3Jpg} loading="lazy" alt="Praxis Wartezimmer" />
							</picture>
						</ImgItem>
					</ImgContainer>
				</PraxisContainer>
			</PraxisSection>
		</>
	);
};

export default Praxis;
