import { SliderData } from './Data';
import { SliderContainer, SliderYear, SliderJob } from './SliderStyles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

// install Swiper modules
SwiperCore.use([Pagination]);

const Slider = () => {
	return (
		<>
			<SliderContainer>
				<Swiper
					id="main"
					pagination={{ clickable: true }}
					breakpoints={{
						768: {
							slidesPerView: 2,
						},
						1400: {
							slidesPerView: 3,
						},
					}}
				>
					{SliderData.map(item => {
						return (
							<SwiperSlide key={item.id}>
								<SliderYear>{item.year}</SliderYear>
								<SliderJob>{item.job}</SliderJob>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</SliderContainer>
		</>
	);
};

export default Slider;
