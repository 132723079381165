import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import emailjs from 'emailjs-com';
import routePng from '../../assets/img/kontakt/map_icon.png';
import routeWebp from '../../assets/img/kontakt/map_icon.webp';
import {
	KontaktSection,
	KontaktContainer,
	Left,
	Card,
	CardTop,
	CardPhone,
	CardBottom,
	CardTimes,
	Right,
	RouteImg,
} from './KontaktStyles';
import { IconProvider } from '../../components';
import { MdLocalPhone, MdPhoneIphone, MdMail } from 'react-icons/md';
import toast, { Toaster } from 'react-hot-toast';

const Kontakt = () => {
	// Animations
	const { ref, inView } = useInView({ threshold: 0.2 });
	const animationLeft = useAnimation();
	const animationRight = useAnimation();

	// messages after submitting form
	const sending = () => toast.loading('Nachricht wird gesendet...');
	const success = () => toast.success('Vielen Dank für Ihre Nachricht');
	function sendEmail(e) {
		e.preventDefault();

		emailjs
			.sendForm(
				'gmail',
				'gmail_template',
				e.target,
				'user_LJkmdlfJ9CugcLMe63xic'
				// access token a67e9aa0889dbd6e2393290f2c30a54c
			)
			.then(result => {
				success();
				return result;
			});

		sending();
		e.target.reset();
	}

	useEffect(() => {
		if (inView) {
			animationLeft.start({
				opacity: 1,
				rotateY: 0,
				transition: {
					type: 'spring',
					duration: 2,
				},
			});
			animationRight.start({
				opacity: 1,
				x: 0,
				transition: {
					type: 'spring',
					duration: 1.5,
					bounce: 0.05,
				},
			});
		}
		if (!inView) {
			animationLeft.start({
				opacity: 0,
				rotateY: 360,
			});
			animationRight.start({
				opacity: 0,
				x: 50,
			});
		}
	}, [inView]);

	return (
		<KontaktSection id="kontakt">
			<KontaktContainer ref={ref}>
				<Left animate={animationLeft}>
					<div className="spacer">
						<Card>
							<CardTop>
								<h2>Praxis f&uuml;r Osteopathie</h2>
								<h1>torben hansen</h1>
								<p>Grothwisch 8</p>
								<p>25482 Appen</p>
								<CardPhone>
									<IconProvider size="18px" color="#666">
										<p>
											<MdLocalPhone />
											<a href="tel:+4941013953957">
												{' '}
												+49 (0) 41 01 - 395 39 57
											</a>
										</p>
										<p>
											<MdPhoneIphone />
											<a href="tel:+4917672340511">
												{' '}
												+49 (0) 17 6 - 723 405 11
											</a>
										</p>
										<p>
											<MdMail />
											<a href="mailto:th@osteopathie-hansen.de">
												{' '}
												th@osteopathie-hansen.de
											</a>
										</p>
									</IconProvider>
								</CardPhone>
							</CardTop>
							<CardBottom>
								<CardTimes>
									<p>&Ouml;ffnungszeiten</p>
									<div>
										<p>Montag - Freitag</p>
										<p>08.00 - 18.00 Uhr</p>
									</div>
									<p className="small">Abendtermine nach Vereinbarung</p>
								</CardTimes>
							</CardBottom>
						</Card>
						<div className="route">
							<a
								href="https://tinyurl.com/yj4sg6jo"
								target="_blank"
								rel="noopener noreferrer"
							>
								<RouteImg>
									<source
										srcSet={routeWebp}
										type="image/webp"
										alt="google maps route icon"
									/>
									{/* <source
										srcSet={routePng}
										type="image/png"
										alt="google maps route icon"
									/> */}
									<img src={routePng} alt="google maps route icon" />
								</RouteImg>
								<p className="span">Route planen (Google Maps)</p>
							</a>
						</div>
					</div>
				</Left>
				<hr />
				<Right animate={animationRight} className="column">
					<h2>Kontakt aufnehmen</h2>
					<form onSubmit={sendEmail}>
						<div>
							<input type="text" placeholder="Name*" name="name" />
						</div>
						<div>
							<input type="email" placeholder="E-Mail Adresse*" name="email" />
						</div>
						<div>
							<input type="text" placeholder="Telefonnummer" name="phone" />
						</div>
						<div>
							<textarea
								id=""
								cols="30"
								rows="8"
								placeholder="Nachricht"
								name="message"
							></textarea>
						</div>
						<motion.div
							whileHover={{
								scale: 1.1,
								transition: {
									repeat: 'Infinity',
									repeatType: 'mirror',
									duration: 0.5,
								},
							}}
						>
							<input type="submit" value="Nachricht senden"></input>
						</motion.div>
					</form>
					<Toaster
						position="bottom-center"
						reverseOrder={false}
						gutter={8}
						containerClassName=""
						containerStyle={{}}
						toastOptions={{
							// Define default options
							className: '',
							duration: 3000,
							style: {
								background: '#fbfbfb',
								color: '#161616',
								padding: '16px 20px',
								fontSize: 18,
							},
							// Default options for specific types
							success: {
								duration: 5000,
								theme: {
									primary: '#68ada8',
									secondary: '#B0A89B',
								},
								style: {
									background: '#68ada8',
									color: '#fff',
								},
							},
							error: {
								duration: 5000,
								theme: {
									primary: 'crimson',
									secondary: '#fff',
								},
								style: {
									background: 'crimson',
									color: '#fff',
								},
							},
						}}
					/>
				</Right>
			</KontaktContainer>
		</KontaktSection>
	);
};

export default Kontakt;
