import styled from 'styled-components/macro';
import { Link as LinkR } from 'react-router-dom';

export const FooterSection = styled.section`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	background-color: #303030;
`;

export const Container = styled.div`
	width: 90%;
	margin: var(--margin-mobile-auto);
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media screen and (min-width: 768px) {
		margin: var(--margin-tablet-auto);
	}

	@media screen and (min-width: 1025px) {
		width: 80%;
		margin: var(--margin-desktop);
	}
`;

export const Content = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	hr {
		margin: 48px 0;
	}

	@media screen and (min-width: 1025px) {
		flex-direction: row;
		align-items: flex-start;

		hr {
			display: none;
		}
	}
`;

export const Items = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	h3 {
		margin: 0 0 8px;
	}

	p {
		margin: 0;
		color: var(--clr-white);
		font-size: 1.6rem;
		font-weight: 100;
	}

	a {
		margin-bottom: 16px;
		color: var(--clr-primary);

		&:hover {
			color: var(--clr-secondary-dark);
		}

		&:last-child {
			margin: 0;
		}
	}

	@media screen and (min-width: 1025px) {
		margin: 0 auto;
		a,
		h3 {
			margin: 0 0 8px;
			align-self: flex-start;
		}
	}
`;

export const Link = styled(LinkR)``;

export const Rights = styled.div`
	width: 100%;
	padding: 16px 0;
	display: flex;
	justify-content: center;
	background-color: var(--clr-dark);

	p {
		margin: 0;
		color: var(--clr-white);
		font-size: 1.6rem;
		font-weight: 100;
	}
`;
