import PropTypes from 'prop-types';
import { Data } from './Data';
import { IconProvider } from '../../components';
import {
	FiPlus,
	FiMinus,
	FiArrowRight,
	FiArrowRightCircle,
} from 'react-icons/fi';
import { AccordionItems, AccordionItem, Action } from './ActionStyles';
import { AccordionDetail } from '../../components';

const AccordionAction = ({ clicked, toggleAccordion }) => {
	// check if Accordion Detail is showed below the action
	const browserWidth = window.innerWidth;

	// Initialize icons variables
	let icon = '';
	let iconClicked = '';

	// Change icons on browserWidth
	// Icons for large screens
	if (browserWidth > 1025) {
		icon = <FiArrowRight />;
		iconClicked = <FiArrowRightCircle />;
	}

	// Icons for small screens
	if (browserWidth < 1350) {
		icon = <FiPlus />;
		iconClicked = <FiMinus />;
	}

	return (
		<IconProvider size="20px" color="#666">
			<AccordionItems>
				{Data.map((item, id) => {
					return (
						<AccordionItem key={item.id}>
							<Action
								onClick={() => toggleAccordion(id)}
								clicked={clicked}
								key={item.id}
							>
								<h3>{item.leistung}</h3>
								<span>{clicked === id ? iconClicked : icon}</span>
							</Action>

							{/* Accordion Detail only for small screens */}
							{clicked === id && browserWidth < 1350 ? (
								<AccordionDetail
									toggleAccordion={toggleAccordion}
									clicked={clicked}
								/>
							) : null}
						</AccordionItem>
					);
				})}
			</AccordionItems>
		</IconProvider>
	);
};

export default AccordionAction;

// Declaring PropTypes (eslint)
AccordionAction.propTypes = {
	clicked: PropTypes.number,
	toggleAccordion: PropTypes.func,
};
