import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`

:root {
//* Fonts
--ff-primary: 'Cinzel', serif;
--ff-content: 'Open Sans', sans-serif;

//* Colors
--clr-primary: #68ada8;
--clr-primary-dark: #589e99;
--clr-primary-light: #eef6f5;
--clr-secondary: #B0A89B;
--clr-secondary-dark: #8f877b;
--clr-sidebar-links: #77c5c0;
--clr-content: #161616;
--clr-middle: #666;
--clr-middle-grey: #999999;
--clr-lightgrey: #dfdfdf;  
--clr-light: #fbfbfb;
--clr-white: #fff;

//* Gradients
--grad-h1-primary: linear-gradient(to bottom, #68ada8 15%, #66b9b4 85%);
--grad-btn-primary: linear-gradient(178deg, #7dcac5 10%, #68ada8 90%);
--grad-hero: linear-gradient(rgba(22, 15, 7, 0.6), rgba(22, 15, 7, 0.4));
--grad-primary: linear-gradient(180deg,rgba(238, 246, 245, 1), #68ada8);
--grad-primary-reverse: linear-gradient(180deg, #A8D0CD, rgba(238, 246, 245, 1));
--grad-primary-tb-light: linear-gradient(180deg, rgba(238, 246, 245, .4), rgb(168, 208, 205,.4), rgba(238, 246, 245, .8));
--grad-hr: linear-gradient(90deg, rgba(104, 173, 168, 0), rgba(104, 173, 168, .7) 50%, rgba(104, 173, 168, 0));
--grad-hr-white: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, .7) 50%, rgba(255, 255, 255, 0));
--grad-primary-bg: linear-gradient(90deg,rgba(104, 173, 168, 0.5), rgba(104, 173, 168, 0.6) , rgba(104, 173, 168, 0.5));
--card-bottom: linear-gradient(90deg,rgba(104, 173, 168, 0.5), rgba(106, 183, 177, 0.6) , rgba(104, 173, 168, 0.4));


//* Shadows
// Box Shadows
--bsd-light: 8px 8px 48px -22px rgba(150, 150, 150, 0.4);
--bsd-middle: 8px 8px 48px -22px rgba(0, 0, 0, 0.6);
--bsd-btn: 2px 2px 8px rgba(101, 170, 171, 0.35);
--bsd-btn-schedule: 2px 2px 8px rgba(73, 125, 126, 0.35);
// Text Shadows
--tsd-primary: 0.1rem 0.1rem 0 rgba(49, 96, 93, 0.4);
--tsd-dark: 0.1rem 0.1rem 0 rgba(22, 22, 22, 0.4);

//* Margins & Paddings
--margin-mobile: 48px 0;
--margin-mobile-auto: 64px auto;
--margin-tablet: 64px 0;
--margin-tablet-auto: 80px auto;
--margin-desktop: 72px 0;
--margin-desktop-auto: 72px auto;
}

//* Global
*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

html, body {
	height: 100%;
	font-family: 'Open Sans', sans-serif;
	font-size: 10px;
  font-family: var(--ff-content);
}


//* Elements
a {
	text-decoration: none;
  font-size: 1.6rem;
  line-height: normal;
	cursor: pointer;
	color: var(--clr-primary);

	&:hover {
		color: var(--clr-secondary-dark);
	}
}

p {
  margin-bottom: 24px;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: 1px;
  color: var(--clr-content);
}

hr {
		width: 60%;
		height: 1px;
		margin: 16px 0;
		border: none;
		background: var(--grad-hr);
		align-self: center;

		@media screen and (min-width: 768px) {
		margin: 32px 0;
		}

		@media screen and (min-width: 1025px) {
		margin: 24px 0;
		}
	}

//- Headlines
h1 {
	margin: 0 0 32px 0;
	font-family: var(--ff-primary);
	font-size: 3rem;
	text-transform: uppercase;
	line-height: 1;
	letter-spacing: 3.4px;
	color: var(--clr-primary);
}

h1:not(.Hero) {
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-image: var(--grad-h1-primary);
}

h2 {
	margin: 0 1.4px 32px 0;
  font-family: var(--ff-primary);
	text-transform: uppercase;
  font-size: 1.6rem;
  font-weight: 300;
	line-height: 1.15;
	letter-spacing: 1.4px;
  color: var(--clr-middle);
}

h3 {
  margin: 24px 0;
  font-family: var(--ff-content);
  font-weight: 400;
  font-size: 1.6rem;
	letter-spacing: 1px;
	color: var(--clr-middle);
}

//! Media Queries

@media screen and (max-width: 340px) {
	a {
		font-size: 1.4rem;
	}

	p {
		font-size: 1.4rem;
	}

  h1 {
		font-size: 2.2rem;
		line-height: 0.9;
	}

  h2 {
		font-size: 1.2rem;
		letter-spacing: 1.2px;
  }

  h3 {
		font-size: 1.4rem;
		letter-spacing: 1.2px;
  }

	li {
		font-size: 1.4rem;
	}
}

@media screen and (min-width: 768px) {
  //- Headlines
  h1 {
		margin: 0 0 48px 0;
		font-size: 4.8rem;
		line-height: 0.9;
	}

  h2 {
		font-size: 2.5rem;
		letter-spacing: 1.8px;
	}

  h3 {
		font-size: 1.8rem;
		letter-spacing: 1.8px;
	}

	//- Text and Objects
	a {
		font-size: 1.8rem;
	}

  p {
    font-size: 1.8rem;
    line-height: 1.5;
  }

	hr {
		width: 50%;
	}

	li {
		font-size: 1.8rem;
		line-height: 1.5;
	}
}
`;

export default GlobalStyle;
