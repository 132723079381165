import styled from 'styled-components/macro';

export const DetailItem = styled.div`
	width: 100%;
	margin-top: 8px;
	transition: all 1s ease-in-out;

	&:first-child {
		margin-top: 0;
	}

	@media screen and (min-width: 768px) {
		width: 100%x;
	}

	@media screen and (min-width: 1350px) {
		width: 90%;
		align-self: center;
	}
`;

export const Detail = styled.div`
	width: 100%;
	position: relative;
	margin-top: 4px;
	padding: 8px 16px;
	border-radius: 4px;
	text-align: left;
	box-shadow: var(--bsd-light);
	background: #fff;

	&::before,
	::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 4px;
	}

	&::after {
		border: 1px solid var(--clr-middle-grey);
		opacity: 0.2;
	}

	p {
		margin-top: 16px;
		margin-bottom: 0;

		&:first-child {
			margin-top: 0;
		}
	}

	@media screen and (min-width: 1025px) {
		padding: 16px;
	}
`;
