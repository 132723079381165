import styled from 'styled-components';
import { Link as LinkS } from 'react-scroll';
import { motion } from 'framer-motion';

export const BrandAnimate = styled(motion.div)`
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const BrandContainer = styled(LinkS)`
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
`;

export const BrandLogo = styled.img`
	height: 33px;
	margin: 1px 4px 0 0;

	@media screen and (min-width: 768px) {
		height: 39px;
		margin: 0 4px 0 0;
	}
`;

export const BrandNames = styled.div`
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
	text-transform: uppercase;
	font-family: var(--ff-primary);
`;

export const BrandTopline = styled.div`
	width: 100%;
	font-family: inherit;
	font-size: 1.4rem;
	letter-spacing: 1.5px;
	line-height: 18px;
	color: ${props => (props.scroll ? '#666' : '#fff')};
	transition: all 0.5s ease;

	@media screen and (max-width: 340px) {
		font-size: 1.2rem;
		letter-spacing: 1.2px;
		line-height: 1;
	}

	@media screen and (min-width: 768px) {
		font-size: 1.6rem;
		letter-spacing: 2.1px;
	}
`;

export const BrandHeadline = styled.div`
	width: 100%;
	font-family: inherit;
	font-size: 2.2rem;
	line-height: 18px;
	letter-spacing: 1.8px;
	color: var(--clr-primary);
	text-shadow: ${props => (props.scroll ? 'none' : `var(--tsd-dark)`)};

	@media screen and (max-width: 340px) {
		font-size: 1.9rem;
		letter-spacing: 1.3px;
		line-height: 0.8;
	}

	@media screen and (min-width: 768px) {
		font-size: 2.6rem;
		letter-spacing: 2.2px;
		line-height: 20px;
	}
`;
