import styled from 'styled-components/macro';
import { Link as LinkR } from 'react-router-dom';
import { Link as LinkS } from 'react-scroll';
import { motion } from 'framer-motion';

export const BtnContainer = styled(motion.div)`
	width: fit-content;
	margin: ${props => (props.margin ? props.margin : '16px 0')};
	padding: ${props => (props.icon ? props.padding : '10px 22px')};
	border: none;
	border-radius: 50px;
	position: ${props => (props.bottom ? 'absolute' : 'relative')};
	bottom: ${props => (props.bottom ? props.position : null)};
	right: ${props => (props.bottom ? props.position : null)};
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background: var(--grad-btn-primary);
	font-size: 1.6rem;
	letter-spacing: 1px;
	text-decoration: none;
	text-transform: uppercase;
	color: var(--clr-white);
	text-shadow: var(--tsd-primary);
	box-shadow: var(--bsd-btn);
	cursor: pointer;

	@media screen and (max-width: 340px) {
		padding: 14px 18px;
		font-size: 1.2rem;
	}

	@media screen and (min-width: 768px) {
		font-size: 1.8rem;
	}
`;

// Links
export const BtnLinkR = styled(LinkR)`
	color: inherit;

	&:hover {
		color: var(--clr-white);
	}
`;

export const BtnLinkS = styled(LinkS)`
	color: inherit;

	&:hover {
		color: var(--clr-white);
	}
`;
