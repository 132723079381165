import styled from 'styled-components/macro';

export const AccordionItems = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	white-space: pre-line;

	@media screen and (min-width: 1025px) {
		width: 600px;
		align-items: flex-start;
	}
`;

export const AccordionItem = styled.div`
	width: 100%;
	transition: all 1s ease-in-out;
	margin-top: 8px;

	&:first-child {
		margin-top: 0;
	}

	@media screen and (min-width: 768px) {
		width: 600px;
	}

	@media screen and (min-width: 1025px) {
		width: 100%;
	}
`;

export const Action = styled.div`
	width: 100%;
	padding: 8px 16px;
	display: flex;
	position: relative;
	justify-content: space-between;
	align-items: center;
	border-radius: 4px;
	background: var(--grad-primary-bg);
	cursor: pointer;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;
		border-radius: 4px;
		border: 1px solid var(--clr-middle-grey);
		opacity: 0.2;
	}

	@media screen and (min-width: 1025px) {
		padding: 16px;
	}

	h3 {
		margin: 0;
		font-weight: 300;
		color: var(--clr-content);
	}
`;
