import { motion } from 'framer-motion';
import {
	HeroSection,
	VideoContainer,
	VideoBG,
	HeroContainer,
	Headlines,
	Topline,
	Headline,
	Quotes,
	QuotesImg,
	QuotesContent,
} from './HeroStyles';
import poster from '../../assets/img/hero/poster.jpg';
import video from '../../assets/videos/bg_vid.mp4';
import quotesWebp from '../../assets/img/hero/quotes.webp';
import quotesPng from '../../assets/img/hero/quotes.png';
// import { Btn, BtnSchedule } from '../../components';
import { Btn } from '../../components';

// Component
const Hero = () => {
	return (
		<>
			<VideoContainer>
				<VideoBG
					autoPlay
					playsInline
					loop
					muted
					src={video}
					type="video/mp4"
					poster={poster}
				/>
			</VideoContainer>
			<HeroSection id="home">
				<HeroContainer>
					<Headlines>
						<Topline variants={aTopline} initial="hidden" animate="show">
							ihre praxis im westen hamburgs
						</Topline>
						<Headline
							variants={aHeadline}
							initial="hidden"
							animate="show"
							className="Hero"
						>
							Osteopathie
						</Headline>
						<motion.hr variants={aDivider} initial="hidden" animate="show" />
						<Quotes variants={aQuotes} initial="hidden" animate="show">
							<QuotesImg>
								<source srcSet={quotesWebp} type="image/webp" />
								{/* <source srcSet={quotesPng} type="image/png" /> */}
								<img src={quotesPng} />
							</QuotesImg>
							<QuotesContent>
								Ich glaube, dass alle Heilmittel, die wir für unsere Gesundheit
								brauchen, im menschlichen Körper vorhanden sind. - A.T. Still
							</QuotesContent>
						</Quotes>
						<div className="centered">
							<Btn
								to="therapeut"
								scroll
								initial={{
									opacity: 0,
									x: 100,
								}}
								animate={{
									opacity: 1,
									x: 0,
									transition: {
										delay: 4,
										type: 'spring',
										stiffness: 150,
									},
								}}
								whileHover={{
									scale: 1.1,
									transition: {
										repeat: 'Infinity',
										repeatType: 'mirror',
										duration: 0.5,
									},
								}}
							>
								Mehr erfahren
							</Btn>
						</div>
					</Headlines>
				</HeroContainer>
			</HeroSection>
			{/* <BtnSchedule
				icon
				bottom
				position="32px"
				size="20px"
				color="#ffffff"
				initial={{
					opacity: 0,
					x: 100,
				}}
				animate={{
					opacity: 1,
					x: 0,
					transition: {
						delay: 4,
						type: 'spring',
						stiffness: 150,
					},
				}}
				whileHover={{
					scale: 1.1,
					transition: {
						repeat: 'Infinity',
						repeatType: 'mirror',
						duration: 0.5,
					},
				}}
			/> */}
		</>
	);
};

export default Hero;

// Animations
const aTopline = {
	hidden: {
		opacity: 0,
		x: 100,
	},
	show: {
		opacity: 1,
		x: 0,
		transition: {
			delay: 2.5,
			type: 'spring',
			stiffness: 90,
		},
	},
};

const aHeadline = {
	hidden: {
		opacity: 0,
		y: 30,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			delay: 2.6,
			type: 'spring',
			stiffness: 70,
		},
	},
};

const aQuotes = {
	hidden: {
		opacity: 0,
		y: 30,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			delay: 2.9,
			type: 'spring',
			stiffness: 50,
			mass: 1,
		},
	},
};

const aDivider = {
	hidden: { opacity: 0 },
	show: {
		opacity: 1,
		transition: {
			delay: 2.8,
			duration: 0.6,
		},
	},
};
