import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const HeroSection = styled.section`
	width: 100%;
	height: calc(100vh - 96px);
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;
	overflow: hidden;
`;

export const VideoContainer = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: -10;
	top: 0;
	left: 0;

	&:after {
		content: '';
		position: absolute;
		width: 100%;
		min-height: 100%;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background: var(--grad-hero);
	}
`;

export const VideoBG = styled.video`
	width: 100%;
	height: 100vh;
	-o-object-fit: cover;
	object-fit: cover;
	object-position: center;
	background: #000;

	@media screen and (max-width: 768px) {
		object-position: center;
	}
`;

export const HeroContainer = styled.div`
	width: 100%;
	margin: 0 auto 40% auto;
	z-index: 1;
	display: flex;
	flex-flow: column;
	justify-content: flex-end;
	align-items: center;
	font-weight: 300;
	color: var(--clr-white);

	hr {
		margin: 24px 0;
	}

	@media screen and (min-width: 768px) {
		width: 100%;
		margin: 0 auto 25% auto;
		align-items: center;

		hr {
			margin: 32px 0;
		}
	}

	@media screen and (min-width: 1025px) {
		width: 80%;
		height: 100%;
		margin-bottom: 10%;
		align-items: flex-start;
	}
`;

export const Headlines = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;

	hr {
		background: var(--grad-hr-white);
	}

	.centered {
		width: 100%;
		text-align: center;
	}

	@media screen and (min-width: 1025px) {
		align-items: flex-start;
	}
`;

export const Topline = styled(motion.h2)`
	margin: 0 1px 0 0;
	font-weight: 400;
	letter-spacing: 1px;
	color: var(--clr-white);
	text-shadow: var(--tsd-dark);

	@media screen and (min-width: 768px) {
		font-size: 3.2rem;
		letter-spacing: 1.2px;
		letter-spacing: 1.5px;
	}

	@media screen and (min-width: 1025px) {
		margin-left: 8px;
		font-size: 3.1rem;
		font-weight: 300;
		letter-spacing: 1.8px;
	}
`;

export const Headline = styled(motion.h1)`
	margin: 0;
	text-shadow: var(--tsd-dark);
	font-size: 4rem;
	letter-spacing: 2.4px;

	@media screen and (max-width: 340px) {
		font-size: 3rem;
		line-height: 0.9;
		letter-spacing: 3px;
	}

	@media screen and (min-width: 768px) {
		font-size: 8rem;
		line-height: 0.9;
		letter-spacing: 2.8px;
	}
`;

export const Quotes = styled(motion.div)`
	width: 90%;
	margin: 0 0 24px;
	text-align: center;

	@media screen and (min-width: 768px) {
		display: flex;
		width: 600px;
	}
`;

export const QuotesImg = styled.picture`
	display: none;

	@media screen and (min-width: 768px) {
		display: block;
		height: 90%;
		margin: -10px 0 0;
	}

	@media screen and (min-width: 1025px) {
		margin: -10px -20px 0 0;
	}
`;

export const QuotesContent = styled.div`
	font-size: 1.8rem;
	width: 100%;
`;
