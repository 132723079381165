import React, { Suspense } from 'react';
import GlobalStyle from '../styles/GlobalStyles';
import ScrollToTop from 'react-router-scroll-top';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Layout from '../containers/Layout';
import Home from '../pages';
import { Footer, NotFound } from '../components';

// Lazy Load other routes
const Impressum = React.lazy(() => import('../pages/Impressum/Impressum'));
const Datenschutz = React.lazy(() =>
	import('../pages/Datenschutz/Datenschutz')
);
const Anmeldung = React.lazy(() => import('../pages/Formulare/Anmeldung'));

export default function App() {
	return (
		<Router>
			<ScrollToTop>
				<Layout>
					<GlobalStyle />
					<Suspense fallback={<p>Laden...</p>}>
						<Switch>
							<Route path="/" exact component={Home} />
							<Route path="/impressum">
								<Impressum />
							</Route>
							<Route path="/datenschutz">
								<Datenschutz />
							</Route>
							<Route path="/anmeldung">
								<Anmeldung />
							</Route>
							<Route path="*">
								<NotFound />
							</Route>
						</Switch>
					</Suspense>
					<Footer />
				</Layout>
			</ScrollToTop>
		</Router>
	);
}
