import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { motion, useAnimation } from 'framer-motion';
import {
	LeistungenSection,
	LeistungenContainer,
	Title,
	Left,
	Right,
	Content,
} from './LeistungenStyles';
import { AccordionAction, AccordionDetail } from '../../components';

const Leistungen = ({ clicked, toggleAccordion }) => {
	const { ref, inView } = useInView({ threshold: 0.2 });
	const animationTitle = useAnimation();
	const animationZitat = useAnimation();
	const animationAccordion = useAnimation();
	const animationRight = useAnimation();

	useEffect(() => {
		// console.log('useEffect in View =', inView);
		if (inView) {
			animationTitle.start({
				opacity: 1,
				y: 0,
				transition: {
					type: 'spring',
					duration: 2,
					bounce: 0.3,
				},
			});
			animationZitat.start({
				opacity: 1,
				x: 0,
				transition: {
					type: 'spring',
					duration: 1.5,
					bounce: 0.05,
				},
			});
			animationAccordion.start({
				opacity: 1,
				y: 0,
				transition: {
					type: 'spring',
					delay: 0.5,
					duration: 0.5,
					bounce: 0.05,
				},
			});
			animationRight.start({
				opacity: 1,
				// x: 0,
				rotateY: 0,
				transition: {
					type: 'spring',
					delay: 0.5,
					duration: 1.5,
					bounce: 0.05,
				},
			});
		}
		if (!inView) {
			animationTitle.start({
				opacity: 0,
				y: 30,
			});
			animationZitat.start({
				opacity: 0,
				x: -30,
			});
			animationAccordion.start({
				opacity: 0,
				y: 30,
				transition: { delay: 1, duration: 0.3 },
			});
			animationRight.start({
				opacity: 0,
				rotateY: 180,
				// x: 100,
				transition: { delay: 1, duration: 0.3 },
			});
		}
	}, [inView]);

	return (
		<>
			<LeistungenSection id="leistungen">
				<LeistungenContainer ref={ref}>
					<Left>
						<Title animate={animationTitle}>was ist osteopathie?</Title>
						<Content>
							<motion.div animate={animationZitat} className="Zitat">
								<p>
									Die Osteopathie ist ein Heilungssystem, das sowohl innere als
									auch &auml;ußere Krankheiten durch manuelle Behandlung ohne
									den Einsatz von Medikamenten erreicht.
								</p>
								<p className="Urheber">A.T. Still</p>
								<p className="Quelle">
									Aus: Das große Still Kompendium. Pähl: Jolandos; 2005: I-149
								</p>
							</motion.div>
							<hr />
							<motion.div
								animate={animationAccordion}
								style={{ width: '100%' }}
							>
								<h3>Ich biete folgende Leistungen an:</h3>
								<AccordionAction
									clicked={clicked}
									toggleAccordion={toggleAccordion}
								/>
							</motion.div>
						</Content>
					</Left>
					<Right animate={animationRight}>
						<AccordionDetail
							clicked={clicked}
							toggleAccordion={toggleAccordion}
						/>
					</Right>
				</LeistungenContainer>
			</LeistungenSection>
		</>
	);
};

export default Leistungen;

// Declaring PropTypes (eslint)
Leistungen.propTypes = {
	clicked: PropTypes.number,
	toggleAccordion: PropTypes.func,
};
