import PropTypes from 'prop-types';
import { BtnContainer, BtnLinkR, BtnLinkS } from './BtnStyles';

const Btn = props => {
	return (
		<BtnContainer
			margin={props.margin}
			padding={props.padding}
			bottom={props.bottom}
			position={props.position}
			initial={props.initial}
			animate={props.animate}
			whileHover={props.whileHover}
		>
			{props.scroll ? (
				<BtnLinkS
					className="Btn"
					to={props.to}
					smooth
					duration={500}
					spy
					exact="true"
					offset={-96}
				>
					{props.children}
				</BtnLinkS>
			) : (
				<BtnLinkR className="Btn" to={props.to} exact="true">
					{props.children}
				</BtnLinkR>
			)}
		</BtnContainer>
	);
};

export default Btn;

// Declaring PropTypes (eslint)
Btn.propTypes = {
	margin: PropTypes.string,
	padding: PropTypes.string,
	bottom: PropTypes.bool,
	position: PropTypes.string,
	initial: PropTypes.object,
	animate: PropTypes.object,
	whileHover: PropTypes.object,
	scroll: PropTypes.bool,
	to: PropTypes.string,
	children: PropTypes.any,
};
