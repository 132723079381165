// import { BtnSchedule } from '../../components';

export const SliderData = [
	{
		id: 'slide-1',
		year: '- 2001',
		job: 'Ausb. Physiotherapeut \nin Damp',
	},
	{
		id: 'slide-2',
		year: '2002 - 2007',
		job: 'Osteopathie-Studium \nin Hamburg',
	},
	{
		id: 'slide-3',
		year: '2007 - 2009',
		job: 'Osteopath \nRehazentrum Pinneberg',
	},
	{
		id: 'slide-4',
		year: '2009 - 2013',
		job: 'Osteopath \nRehazentrum Schenefeld',
	},
	{
		id: 'slide-5',
		year: 'seit 2013',
		job: 'Osteopath \neigene Praxis in Appen',
	},
	{
		id: 'slide-6',
		year: <span>ab 01.01.2022</span>,
		job: 'Termin \nonline buchen',
		// 	(
		// 	<div>
		// 		<BtnSchedule size="20px" color="#ffffff" />
		// 	</div>
		// ),
	},
];
