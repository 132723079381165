import PropTypes from 'prop-types';
import { IconContext } from 'react-icons';

const IconProvider = ({ size, color, children }) => {
	return (
		<IconContext.Provider
			value={{ style: { fontSize: `${size}`, color: `${color}` } }}
		>
			{children}
		</IconContext.Provider>
	);
};

export default IconProvider;

IconProvider.propTypes = {
	size: PropTypes.string,
	color: PropTypes.string,
	children: PropTypes.any,
};
