import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	Nav,
	NavBG,
	NavAnimate,
	NavbarContainer,
	MobileIcon,
	NavMenu,
	NavItem,
	NavLink,
} from './ToolbarStyles';
// import { Brand, Sidebar, BtnSchedule } from '../../components';
import { Brand, Sidebar } from '../../components';

const Toolbar = ({ isOpen, toggle }) => {
	// change nav props on scroll 1/4 page
	const [scroll, setScroll] = useState(false);
	const vh = window.innerHeight / 4;

	const changeNav = () => {
		if (window.scrollY > vh) {
			setScroll(true);
		} else {
			setScroll(false);
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', changeNav);

		return () => {
			window.removeEventListener('scroll', changeNav);
		};
	}, []);

	return (
		<>
			<NavBG scroll={scroll} />
			<Nav>
				<NavbarContainer>
					<Brand scroll={scroll} />
					<MobileIcon
						scroll={scroll}
						isOpen={isOpen}
						onClick={toggle}
						variants={aMobile}
						initial="hidden"
						animate="show"
					>
						<div></div>
						<div></div>
						<div></div>
					</MobileIcon>
					<NavAnimate variants={aMenu} initial="hidden" animate="show">
						<NavMenu scroll={scroll}>
							<NavItem>
								<NavLink
									to="therapeut"
									smooth
									duration={500}
									spy={true}
									exact="true"
									offset={-96}
									className="Link"
								>
									Therapeut
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									to="praxis"
									smooth
									duration={500}
									spy
									exact="true"
									offset={-96}
									className="Link"
								>
									Praxis
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									to="leistungen"
									smooth
									duration={500}
									spy
									exact="true"
									offset={-96}
									className="Link"
								>
									Leistungen
								</NavLink>
							</NavItem>
							<NavItem>
								<NavLink
									to="kontakt"
									smooth
									duration={500}
									spy
									exact="true"
									offset={-96}
									className="Link"
								>
									Kontakt
								</NavLink>
							</NavItem>
							{/* <BtnSchedule icon margin="12px" size="18px" color="#ffffff" /> */}
						</NavMenu>
					</NavAnimate>
					<Sidebar isOpen={isOpen} toggle={toggle} />
				</NavbarContainer>
			</Nav>
		</>
	);
};

export default Toolbar;

// Declaring PropTypes (eslint)
Toolbar.propTypes = {
	isOpen: PropTypes.bool,
	toggle: PropTypes.func,
};

// Animations
const aMenu = {
	hidden: {
		opacity: 0,
		y: -100,
	},
	show: {
		opacity: 1,
		y: 0,
		transition: {
			delay: 1.6,
			type: 'spring',
			stiffness: 120,
		},
	},
};

const aMobile = {
	hidden: {
		opacity: 0,
		x: 100,
	},
	show: {
		opacity: 1,
		x: 0,
		transition: {
			delay: 0.4,
			type: 'spring',
			stiffness: 120,
		},
	},
};
