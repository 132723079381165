import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';

const NotFound = () => {
	return (
		<Container>
			<h2>Ups... da ist was schief gelaufen</h2>
			<p>Diese Seite kann nicht gefunden werden!</p>
			<Link to="/">Zur&uuml;ck zur Startseite</Link>
		</Container>
	);
};

export default NotFound;

const Container = styled.div`
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	h2 {
		margin: 48px 0;
	}
`;

const Link = styled(LinkR)`
	margin: 48px auto;
`;
