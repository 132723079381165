import styled from 'styled-components/macro';

export const SliderContainer = styled.div`
	width: 100%;
	height: 150px;
	display: flex;
	-ms-overflow-style: none; /* hide scrollbar in IE and Edge */
	scrollbar-width: none; /* hide scrollbar in Firefox */
	overflow: hidden;
	margin-top: 16px;
	white-space: pre-line;
	background-color: transparent;
	cursor: move;

	.swiper-container {
		width: 100%;
		height: 100%;
	}

	.swiper-pagination-bullets {
		background-color: #f3f3f3;
		margin-top: 8px;
		bottom: 0px;
	}

	.swiper-pagination-bullet-active {
		background-color: var(--clr-primary);
	}

	::-webkit-scrollbar,
	::-webkit-scrollbar {
		display: none;
	}

	@media screen and (min-width: 1025px) {
		margin: 0;
		height: 170px;
	}

	@media screen and (min-width: 1550px) {
		margin: 0;
		height: 150px;
	}
`;

export const SliderYear = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 8px 0 8px 4px;
	font-size: 1.6rem;
	font-weight: 300;
	border-right: 4px solid transparent;

	span {
		font-size: 1.6rem;
		font-weight: 400;
	}

	@media screen and (min-width: 768px) {
		font-size: 1.8rem;

		span {
			font-size: 1.8rem;
		}
	}
`;

export const SliderJob = styled.div`
	min-height: 77px;
	padding: 16px 4px;
	border-right: 4px solid #ebebeb;
	display: flex;
	text-align: center;
	justify-content: center;
	background: var(--grad-primary-bg);
	font-size: 1.6rem;
	font-weight: 300;

	@media screen and (min-width: 768px) {
		min-height: 84px;
		font-size: 1.8rem;

		span {
			font-size: 1.8rem;
		}
	}

	@media screen and (min-width: 1025px) {
		div {
			margin-top: 3px;
		}
	}
`;
