import styled from 'styled-components/macro';
import { motion } from 'framer-motion';

export const PraxisSection = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	overflow: hidden;

	@media screen and (min-width: 1200px) {
		min-height: calc(100vh - 96px);
	}
`;

export const PraxisContainer = styled.div`
	width: 90%;
	height: 100%;
	margin: var(--margin-mobile-auto);
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 768px) {
		margin: var(--margin-tablet-auto);
	}

	@media screen and (min-width: 1025px) {
		width: 80%;
		margin: var(--margin-desktop-auto);
	}
`;

export const ImgContainer = styled.div`
	width: 100%;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	row-gap: 24px;

	@media screen and (min-width: 768px) {
		display: grid;
		grid-template: 2fr 1fr / 1fr 1fr;
		row-gap: 20px;
		column-gap: 24px;
	}

	@media screen and (min-width: 1400px) {
		display: grid;
		grid-template: 1fr 1fr / 2fr 1fr;
		row-gap: 20px;
		column-gap: 24px;
	}
`;

export const ImgItem = styled(motion.div)`
	width: 100%;

	picture img {
		width: 100%;
		height: 100%;
		border-radius: 2px;
		box-shadow: var(--bsd-middle);
	}

	@media screen and (min-width: 768px) {
		&:nth-child(1) {
			grid-row: 1 / 2;
			grid-column: 1 / 3;
		}
		&:nth-child(2) {
			grid-row: 2 / 3;
			grid-column: 1 / 2;
		}
		&:nth-child(3) {
			grid-row: 2 / 3;
			grid-column: 2 / 3;
		}
	}

	@media screen and (min-width: 1400px) {
		&:nth-child(1) {
			grid-row-start: 1;
			grid-row-end: 3;
			grid-column: 1 / 2;
		}
		&:nth-child(2) {
			grid-row: 1 / 2;
			grid-column: 2 / 3;
		}
		&:nth-child(3) {
			grid-row: 2 / 3;
			grid-column: 2 / 3;
		}
	}
`;
