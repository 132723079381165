import styled from 'styled-components/macro';
import { motion } from 'framer-motion';
import bg_leistungen from '../../assets/img/leistungen/bg_leistungen.webp';

export const LeistungenSection = styled.section`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	position: relative;
	overflow: hidden;
	background-color: #f3f3f3;
`;

export const LeistungenContainer = styled.div`
	width: 90%;
	height: 100%;
	margin: var(--margin-mobile-auto);
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 768px) {
		min-height: 100%;
		margin: var(--margin-tablet-auto);
	}

	@media screen and (min-width: 1025px) {
		width: 80%;
		flex-direction: row;
		align-items: flex-start;
		min-height: calc(100vh - 230px);
	}

	@media screen and (min-width: 1500px) {
		min-height: calc(100vh - 96px);
		margin: 0;
	}
`;

export const Left = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;

	@media screen and (min-width: 1350px) {
		width: 50%;
		align-items: flex-start;
	}

	@media screen and (min-width: 1500px) {
		align-self: center;
	}
`;

export const Right = styled(motion.div)`
	display: none;

	@media screen and (min-width: 1350px) {
		width: calc(50vw - 24px);
		height: 75vh;
		margin: 0 0 0 10%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		background-image: url(${bg_leistungen});
		background-size: cover;
		background-repeat: no-repeat;
		background-position: top right;
		border-radius: 24px;
		border: 1px solid var(--clr-lightgrey);
	}

	@media screen and (min-width: 1500px) {
		align-self: center;
		margin: 32px 0 32px 10%;
	}
`;

// Left content
export const Title = styled(motion.h1)``;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	.Zitat {
		width: 100%;
		margin: 0 0 32px 0;
		padding: 16px;
		position: relative;
		font-size: 1.6rem;
		font-weight: 300;
		line-height: 1.25;
		letter-spacing: 1px;
		color: var(--clr-content);
		box-shadow: var(--bsd-light);
		background: var(--grad-primary-bg);

		&::after {
			content: '';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: -1;
			border-radius: 4px;
			border: 1px solid var(--clr-middle-grey);
			opacity: 0.15;
		}

		.Urheber {
			margin: 8px 0;
			font-size: inherit;
			font-weight: 400;
			text-align: center;

			@media screen and (max-width: 340px) {
				font-size: 1.4rem;
			}
		}

		.Quelle {
			margin: 0 10%;
			padding-bottom: 8px;
			font-size: 1.2rem;
			font-style: italic;
			color: var(--clr-middle);
			text-align: center;
		}

		@media screen and (min-width: 768px) {
			width: 600px;
		}
	}
`;
