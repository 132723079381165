import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import torbenMobileWebp from '../../assets/img/therapeut/torben_mobile.webp';
import torbenMobilePng from '../../assets/img/therapeut/torben_mobile.png';
import {
	TherapeutSection,
	TherapeutContainer,
	Top,
	Headlines,
	Content,
	SmallImg,
	SliderAnimate,
	SliderContainer,
} from './TherapeutStyles';
import { Slider } from '../../components';

const Therapeut = () => {
	const { ref, inView } = useInView({ threshold: 0.2 });
	const animationTop = useAnimation();
	const animationContent = useAnimation();
	const animationSlider = useAnimation();

	// Animation on scroll when in viewport
	useEffect(() => {
		if (inView) {
			animationTop.start({
				opacity: 1,
				y: 0,
				transition: {
					type: 'spring',
					duration: 2,
					bounce: 0.3,
				},
			});
			animationContent.start({
				opacity: 1,
				x: 0,
				transition: {
					type: 'spring',
					duration: 1.5,
					bounce: 0.05,
				},
			});
			animationSlider.start({
				opacity: 1,
				y: 0,
				transition: {
					type: 'spring',
					delay: 0.5,
					duration: 0.8,
					bounce: 0.05,
				},
			});
		}
		if (!inView) {
			animationTop.start({
				opacity: 0,
				y: 50,
			});
			animationContent.start({
				opacity: 0,
				x: -100,
			});
			animationSlider.start({
				opacity: 0,
				y: 100,
				transition: { delay: 1, duration: 0.3 },
			});
		}
	}, [inView]);

	return (
		<>
			<TherapeutSection id="therapeut">
				<TherapeutContainer ref={ref}>
					<Top animate={animationTop}>
						{/* <SmallImg src={torben_mobile} /> */}
						<SmallImg>
							<source
								srcSet={torbenMobileWebp}
								type="image/webp"
								alt="Torben Hansen"
							/>
							<img src={torbenMobilePng} loading="lazy" alt="Torben Hansen" />
						</SmallImg>
						<Headlines>
							<h2>ihr therapeut f&uuml;r osteopathie</h2>
							<h1>Torben Hansen</h1>
						</Headlines>
					</Top>
					<Content animate={animationContent}>
						<p>
							Meine osteopathische Behandlung versteht sich in erster Linie als
							alternative, komplementäre oder erg&auml;nzende Medizin. Sie ist
							geeignet für S&auml;uglinge, Kinder und Erwachsene.
						</p>
						<p>
							Ich lege viel Wert darauf, mir f&uuml;r jeden Patienten Zeit zu
							nehmen, um mich in den Menschen einf&uuml;hlen zu k&ouml;nnen.
						</p>
						<p>
							Regelm&auml;&szlig;ig besuche ich Fortbildungen, um mich
							weiterzuentwickeln.
						</p>
						<p>
							Ich bin Mitglied im&nbsp;
							<a
								href="https://www.osteopathie.de/"
								target="_blank"
								rel="noopener noreferrer"
							>
								Verband der Osteopathen Deutschland e.V. VOD
							</a>
							.
						</p>
					</Content>
					<SliderAnimate animate={animationSlider}>
						<hr />
						<h3>Berufliche Laufbahn und Qualifikationen</h3>
						<SliderContainer>
							<Slider />
						</SliderContainer>
					</SliderAnimate>
				</TherapeutContainer>
			</TherapeutSection>
		</>
	);
};

export default Therapeut;
